// @file Surface Content Picker store

import { ContentPickerSource } from '@@/bits/content_picker'
import { __ } from '@@/bits/intl'
import { unboundedWatch } from '@@/bits/vue'
import { SearchFilters } from '@@/bits/web_search'
import { useContentPickerStore, type ContentPickerSearchMode } from '@@/pinia/content_picker'
import { useSurfaceDraftsStore } from '@@/pinia/surface_drafts'
import { useSurfacePostPropertiesStore } from '@@/pinia/surface_post_properties'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

export const useSurfaceContentPickerStore = defineStore('surfaceContentPicker', () => {
  const surfacePostPropertiesStore = useSurfacePostPropertiesStore()
  const surfaceDraftsStore = useSurfaceDraftsStore()
  const contentPickerStore = useContentPickerStore()

  const xPostContentPicker = ref(false)

  const showPostContentPicker = (): void => {
    xPostContentPicker.value = true
  }

  const hidePostContentPicker = (): void => {
    xPostContentPicker.value = false
  }

  /* ---------------------- */
  /* SEARCH SUGGESTIONS     */
  /* ---------------------- */
  const searchSuggestions = computed<string[]>(() => {
    const suggestingPost = surfaceDraftsStore.activeDraft
    const suggestingPostSubject = suggestingPost?.subject?.trim()

    const suggestions = new Set(
      suggestingPostSubject != null && suggestingPostSubject !== ''
        ? [suggestingPostSubject].concat(contentPickerStore.searchSuggestions)
        : contentPickerStore.searchSuggestions,
    )
    return [...suggestions]
  })

  /* ---------------------- */
  /* SEARCH MODE            */
  /* ---------------------- */
  const currentSearchModes = computed<ContentPickerSearchMode[]>(() => {
    const modes = [
      {
        key: SearchFilters.Images,
        text: __('Images'),
        visible: surfacePostPropertiesStore.isAttachmentTypeEnabled(ContentPickerSource.ImageSearch),
      },
      {
        key: SearchFilters.Gif,
        text: __('GIF'),
        visible: surfacePostPropertiesStore.isAttachmentTypeEnabled(ContentPickerSource.GifSearch),
      },
      {
        key: SearchFilters.Videos,
        text: __('YouTube'),
        visible: surfacePostPropertiesStore.isAttachmentTypeEnabled(ContentPickerSource.VideoSearch),
      },
      {
        key: SearchFilters.Audio,
        text: __('Spotify'),
        visible: surfacePostPropertiesStore.isAttachmentTypeEnabled(ContentPickerSource.AudioSearch),
      },
      {
        key: SearchFilters.Web,
        text: __('Web'),
        visible: surfacePostPropertiesStore.isAttachmentTypeEnabled(ContentPickerSource.WebBrowser),
      },
    ]
    return modes.filter((mode) => mode.visible)
  })

  const defaultSearchMode = computed<SearchFilters>(() => {
    // If all search modes are available, default to Web. Otherwise, default to the first available mode.
    if (currentSearchModes.value.length === 0 || currentSearchModes.value.length === 5) {
      return SearchFilters.Web
    }
    return currentSearchModes.value[0].key
  })

  // The content picker store is also used in dashboard. When we import the `defaultSearchMode`
  // here as a getter in the pinia store, it creates a coupled dependency. In fact, a dynamic default
  // search mode is only possible on surface because of the post properties settings. On dashboard,
  // we don't have that logic. Therefore, we use this watcher sync mechanism to make sure on surface
  // whether we use content picker store or surface content picker store, we always have the same
  // default search mode.
  void unboundedWatch(
    defaultSearchMode,
    (mode) => {
      contentPickerStore.setDefaultSearchMode(mode)
    },
    { immediate: true },
  )

  return {
    // State
    xPostContentPicker,

    // Getters
    currentSearchModes,
    defaultSearchMode,
    searchSuggestions,

    // Actions
    showPostContentPicker,
    hidePostContentPicker,
  }
})
